//*Packages
import React from 'react';
import {Box, Grid2 as Grid, Typography, Paper, useTheme, Stack, Card, Button} from '@mui/material';
import ElectricBoltIcon from '@mui/icons-material/ElectricBolt';
import FactoryIcon from '@mui/icons-material/Factory';
import ConstructionIcon from '@mui/icons-material/Construction';
import BuildIcon from '@mui/icons-material/Build';

//*Components
import '../styles/global.css'
import Nav from '../components/Nav';
import VideoItem from '../components/VideoItem';
import Section from '../components/Section';
import VerticalTicketItem from '../components/VerticalTicketItem';
import FadeInUpAnimation from '../components/FadeInUp';
import FadeInAnimation from '../components/FadeIn';
import ExecsInfoCard from '../components/ExecsInfoCard';
import Footer from '../components/Footer';
//*Assets
import videoUrl from '../assets/videos/tour-compressed.mp4';
import terralinesSvg from '../assets/images/terralines.svg';
import teamImg from '../assets/images/team-on-site.jpg';
import racksImg from '../assets/images/racks-comp.jpg';
import siteRowsImg from '../assets/images/site-rows.jpg'
import teamInspectionImg from '../assets/images/teamwork-inspection.webp';
import jayHeadshot from '../assets/images/Jay-headshot.webp';
import shykeHeadshot from '../assets/images/shyke-headshot.webp';
import omarHeadshot from '../assets/images/omar-headshot.webp';
import joseHeadshot from '../assets/images/jose-headshot.webp'
import gabeHeadshot from '../assets/images/gabe-headshot.webp'
import OriginsCopy from '../components/OriginsCopy';
import partnersWebp from '../assets/images/partners.webp'


interface HProps {
  activeRoute: string,
  isMobile: boolean
}

export default function Home({activeRoute, isMobile}: HProps): React.JSX.Element {
  const theme = useTheme();


  return (
    <React.Fragment>
    {isMobile ? 
    //Mobile
      <Paper  elevation={0} sx={{position: 'relative', flexGrow: 1, overflowX: 'hidden', borderRadius: '0'}}>
        <Nav activeRoute={activeRoute} isMobile={isMobile}/>
        <Section id='hero' elevation={0} height={85}>
          <Grid container direction='column' spacing={0} sx={{ backgroundColor: theme.palette.primary.main, justifyContent: 'center', alignItems:'center', width: '100%', height: '100%'}}>
            <Grid sx={{height: '67%'}}>
              <Box sx={{
                width: '100%', 
                height: '100%', 
                overflow: 'hidden', 
                boxShadow: '0px 8px 8px rgba(0,0,0, .5)',
                zIndex: 2}}>
                <VideoItem sx={{width: '100%', height: '100%', objectFit: 'cover'}} videoUrl={videoUrl} isMobile={isMobile} typeString={'video/mp4'}/>
              </Box>
            </Grid>
            <Grid sx={{height: '20%'}}>
              <Typography variant='h3' sx={{color: theme.palette.secondary.main,fontSize: 'calc(2vh + 2vw)', textWrap: 'wrap', fontWeight: 'bold', margin: '4cqw'}}>We build, own & operate data centers to support the bitcoin network</Typography>
            </Grid>
            <Grid sx={{height: '10%'}}>
              <Button
                variant='contained'
                color='secondary'
                sx={{transform: 'scale(1.5)', transformOrigin: 'center center'}}
                onClick={() => document.querySelector('#footer-contact')?.scrollIntoView({ behavior: 'smooth' })}>
                Partner with us now
              </Button>
            </Grid>
          </Grid>
        </Section>
        <Section id='services' elevation={0} height={180} sx={{backgroundColor: theme.palette.secondary.main, position: 'relative', zIndex:'0', paddingBottom: '2cqw', display: 'flex', flexDirection:'column', alignItems: 'center', justifyContent: 'center'}}>
          <Box sx={{display: 'flex', flexDirection: 'column', alignItems:'center', justifyContent: 'center', width: '80%', height: '90%', position: 'relative'}}>
            <FadeInAnimation delay= {0.4} sx={{width: '100%', height: '100%'}}>
              <VerticalTicketItem 
                theme={theme}
                icon={FactoryIcon}
                contentTitle='Site Operations'
                content='Let our expert teams manage your Bitcoin mining site’s day-to-day operations and maintenance, from traditional grid power to midstream natural gas solutions. With 95% average uptime across our managed sites, we ensure your mining operations run at peak efficiency.'
                titleFontSize='3.5cqw'
                contentFontSize='2.8cqw'
                iconSx={{width: '10cqw', height: 'auto'}}
              ></VerticalTicketItem>
            </FadeInAnimation>
          </Box>
          <Box sx={{display: 'flex', flexDirection: 'column', alignItems:'center', justifyContent: 'center', width: '80%', height: '90%', position: 'relative'}}>
            <FadeInAnimation delay={0.2}>
              <VerticalTicketItem
                theme={theme}
                icon={ConstructionIcon}
                contentTitle="Site Acquisition & Development"
                content='From site selection to site completion, our dedicated development team handles every step of creating your mining operation. We identify prime locations with reliable power access and transform them into state-of-the-art facilities, managing permits, construction, and infrastructure deployment.'
                titleFontSize='3.5cqw'
                contentFontSize='2.8cqw'
                iconSx={{width: '10cqw', height: 'auto'}}
              ></VerticalTicketItem>
            </FadeInAnimation>
          </Box>
          <Box sx={{display: 'flex', flexDirection: 'column', alignItems:'center', justifyContent: 'center', width: '80%', height: '90%', position: 'relative'}}>
            <FadeInAnimation delay={0.2}>
              <VerticalTicketItem
                theme={theme}
                icon={BuildIcon}
                contentTitle='ASIC Repair'
                content="Expert diagnostics and repair services to maximize your mining hardware's lifespan. Our professionally-trained technicians restore damaged hash boards and ASIC miners to optimal performance, with an above standard successful repair rate."
                titleFontSize='3.5cqw'
                contentFontSize='2.8cqw'
                iconSx={{width: '10cqw', height: 'auto'}}
              ></VerticalTicketItem>
            </FadeInAnimation>
          </Box>
          <Box sx={{display: 'flex', flexDirection: 'column', alignItems:'center', justifyContent: 'center', width: '80%', height: '90%', position: 'relative'}}>
            <FadeInAnimation delay={0.2}>
              <VerticalTicketItem
                theme={theme}
                icon={ElectricBoltIcon}
                contentTitle='Energy Consultation'
                content="Optimize your mining operation's power infrastructure with our expert energy consulting, including curtailment program setup. Our team analyzes consumption patterns, and implements smart curtailment strategies; delivering huge reductions in operational expenses."
                titleFontSize='3.8cqw'
                contentFontSize='2.8cqw'
                iconSx={{width: '10cqw', height: 'auto'}}
              ></VerticalTicketItem>
            </FadeInAnimation>
          </Box>
        </Section>
        <Section id='metrics' elevation={1} height={40} sx={{backgroundColor: theme.palette.primary.main, maxHeight: '20vw', borderRadius: '0', zIndex: 5}}>
          <Box id='home-4-container'position='relative' width='100%' height='100%' display='flex' flexDirection='row' alignItems='center' justifyContent='center' zIndex={0}>
            <Box id='background-svg-container' position={'absolute'} sx={{ display: 'flex', alignItems: 'center', justifyContent:'center', width: '100%', height: '100%', zIndex: 1, overflow: 'hidden'}}>
              <img width='100%' src={terralinesSvg} alt="background svg" />
            </Box>
            <Box id='content-container' position={'absolute'} sx={{ display:'flex', flexDirection: 'row', alignItems: 'center', textAlign: 'center', justifyContent:'space-around', width: '100%', height: '100%', zIndex: 2}}>
                <Box>
                  <Typography sx={{ fontSize: '6cqw', fontWeight: 'bold', textShadow: '0em .3em .5em rgba(0, 0, 0, 0.6) !important', color: theme.palette.secondary.main}}>140</Typography>
                  <Typography sx={{ fontSize: '1.5cqw', fontWeight: 'bold', color: theme.palette.secondary.main, opacity: .8}}>MEGAWATTS DEPLOYED</Typography>
                </Box>
                <Box>
                  <Typography sx={{ fontSize: '6cqw', fontWeight: 'bold', textShadow: '0em .3em .5em rgba(0, 0, 0, 0.6) !important', color: theme.palette.secondary.main}}>~7EH</Typography>
                  <Typography sx={{ fontSize: '1.5cqw', fontWeight: 'bold', color: theme.palette.secondary.main, opacity: .8}}>HASHRATE OPERATED</Typography>
                </Box>
                <Box>
                  <Typography sx={{ fontSize: '6cqw', fontWeight: 'bold', textShadow: '0em .3em .5em rgba(0, 0, 0, 0.6) !important', color: theme.palette.secondary.main}}>7</Typography>
                  <Typography sx={{ fontSize: '1.5cqw', fontWeight: 'bold', color: theme.palette.secondary.main, opacity: .8}}>SITES MANAGED</Typography>
                </Box>
                <Box>
                  <Typography sx={{ fontSize: '6cqw', fontWeight: 'bold', textShadow: '0em .3em .5em rgba(0, 0, 0, 0.6) !important', color: theme.palette.secondary.main}}>40</Typography>
                  <Typography sx={{ fontSize: '1.5cqw', fontWeight: 'bold', color: theme.palette.secondary.main, opacity: .8}}>TEAM MEMBERS</Typography>
                </Box>
            </Box>
          </Box>
        </Section>
        <Section id='team' elevation={0} sx={{padding: '4cqw 10cqw', backgroundColor: theme.palette.secondary.main}}>
            <Stack id='team-container' height='100%' direction='column' alignItems='center' justifyContent='center' sx={{backgroundColor: theme.palette.secondary.main}} >
              <Stack id='team-internal-container' width='100%' height='100%' direction='column' alignItems='center' justifyContent='space-evenly' spacing={2} sx={{backgroundColor: theme.palette.secondary.main}}>
                <ExecsInfoCard
                  name='Jay Zapata'
                  title='CEO'
                  headerColor='#F4DFC2'
                  bio="With over a decade of experience in financial leadership and operational management, Jay has consistently delivered results by implementing strong financial controls and driving efficiency across diverse sectors. He has held key roles, including Optimization Director and Financial Controller for private equity backed portfolio companies, where he focused on financial strategy and operational performance. Before joining Satokie, Jay served as CFO of a $150MM revenue organization, where he oversaw complex financial portfolios and ensured strict regulatory compliance."
                  image={jayHeadshot}
                  imgAlt='Very Handsome Headshot of Jay Zapata'
                  isMobile/>
                <ExecsInfoCard
                  name='Shyke Lowers'
                  title='COO'
                  headerColor='#FDD1AD'
                  bio="Shyke brings a wealth of experience to the role of COO at Satokie Mining. A former Power Trader with vast knowledge in power markets and asset optimization gained through his experience operating a 120+MW battery generation portfolio in the ERCOT Market. Shyke utilizes his Power Markets expertise to lead the team's Demand Response and Power Procurement strategies. Shyke spends his time as COO laser focused on the strategic execution of the optimization initiatives and commercial scaling of Satokie's Operations."
                  image={shykeHeadshot}
                  imgAlt='Very Handsome Headshot of Shyke Lowers'
                  isMobile/>
                <ExecsInfoCard
                  name='Omar Alatorre'
                  title='Director of Site Ops'
                  headerColor='#C5A391'
                  bio="Omar has a demonstrated history of effectively overseeing large projects and guiding cross-functional teams to enhance operational efficiency. He gained extensive experience in operations and management by deploying preventive maintenance and technician operation teams for a 300+ MW Wind Farm portfolio, which is backed by one of the country's major capital infrastructure investors."
                  image={omarHeadshot}
                  imgAlt='Very Handsome Headshot of Omar Alatorre'
                  isMobile/>
                <ExecsInfoCard
                  name='Jose Ortega'
                  title='CCO'
                  headerColor='#F4DFC2'
                  bio="Jose is a founder and builder with successful exits in energy, clean technology & Software as a Service (SaaS), he has demonstrated exceptional entrepreneurial acumen. He played a pivotal role in scaling Revel by securing a $27.4 million Series A funding round and raised $75 million in debt equity for projects in the Midland Basin. His innovative efforts across multiple sectors have generated over $750 million in value under his leadership."
                  image={joseHeadshot}
                  imgAlt='Very Handsome Headshot of Jose Ortega'
                  isMobile/>
                <ExecsInfoCard
                  name='Gabe Procaccini'
                  title='EVP, General Counsel'
                  headerColor='#FDD1AD'
                  bio="Gabe Procaccini is a key executive leading the off-grid/stranded energy solutions business. With over 20 years of experience, he is a seasoned attorney and business advisor specializing in global energy transactions. His expertise spans upstream, midstream, and energy marketing transactions, as well as LNG, energy infrastructure development, energy M&A, joint ventures, and emerging energy products. Mr. Procaccini has a strong focus on decarbonization, including stranded gas solutions, low methane gas certification, and utility-scale battery storage systems."
                  image={gabeHeadshot}
                  imgAlt='Very Handsome Headshot of Gabe Procaccini'
                  isMobile/>
              </Stack>
            </Stack>
        </Section>
        <Section id='origins' elevation={0} sx={{backgroundColor: theme.palette.secondary.main}}>
          <Box padding={'2cqw 6cqw'} color={theme.palette.primary.main}>
            <Typography variant='h3' sx={{fontWeight: 'bold', fontSize: '5cqw', textAlign:'center'}}>Our Origins</Typography>
            <hr style={{height: '.2rem', backgroundColor: theme.palette.primary.main, width: '50%', marginTop: 0}}/>
            <OriginsCopy sx={{lineHeight: '3cqh', fontSize: '3cqw'}}/>
          </Box>
          <Box sx={{display: 'flex', justifyContent: 'center'}}>
            <img src={partnersWebp} alt='Our three very handsome founders' style={{
              width: '90%', borderRadius: '5cqw', padding: '.2rem', background: "linear-gradient(90deg, #172D5E 0%, #C2C2C2 30%, #F8AF51 56%, #D95456 89%)"
            }}/>
          </Box>
        </Section>
        <Section id='home-6' elevation={0} height={125} sx={{backgroundColor: theme.palette.secondary.main, maxHeight: '160vw', borderRadius: '0', zIndex: 0, paddingBottom: '2cqw'}}>
          <Box id='gallery-container' width={'100%'} height={'100%'} sx={{padding: '6cqw', zIndex: 1}}>
            <Grid container id='gallery-parent-grid' spacing={3} width={'100%'} height={'100%'} sx={{zIndex: 2}}>
                <Grid id='gallery-left-column'size={6}>
                  <Box id='collumn-box-container' width={'100%'} height={'100%'}>
                    <Grid id='gallery-left-column-container' container rowSpacing={3} width={'100%'} height={'100%'}>
                      <Grid id='top-left-text' size={12} sx={{height: '30%', width:'100%', zIndex: 3}}>
                        <Box id='top-left-text-box'>
                          <Box id='top-left-text-title'>
                            <FadeInAnimation delay ={0.3}>
                              <Typography variant='h3' sx={{fontWeight: 'bold', fontSize: '4cqw', color: theme.palette.primary.main}}>Setting the Standard for Bitcoin Mining Colocation</Typography>
                            </FadeInAnimation>
                          </Box>
                          <Box id='top-left-text-content' sx={{width: '90%'}}>
                              <FadeInUpAnimation delay={0.5}>
                                <Typography variant='h5' sx={{lineHeight: '3.5cqw', fontSize: '2.5cqw'}}>Our mission is to deliver cutting-edge solutions that optimize energy assets by leveraging Bitcoin Mining as a tool. Satokie Mining aims to embed itself at the intersection of Bitcoin mining and traditional energy markets and assets.</Typography>
                              </FadeInUpAnimation>
                          </Box>
                        </Box>
                      </Grid>
                      <Grid id='bottom-left-img' size={12} sx={{height: '70%', width: '100%', zIndex: 3}}>
                        <Box id='bottom-right-img-1' sx={{width: '100%', height: '100%'}}>
                          <FadeInAnimation delay={0.4} sx={{width: '100%', height: '100%'}}>
                            <img src={racksImg} loading='lazy' style={{objectFit: 'cover', width: '100%', height:'100%'}}  alt="Satokie Racks Image" />
                          </FadeInAnimation>
                        </Box>
                      </Grid>
                    </Grid>
                  </Box>
                </Grid>
                <Grid id='gallery-right-column' size={6} sx={{height: '100%'}}>
                <Box id='collumn-box-container' width={'100%'} height={'100%'}>
                  <Grid id='gallery-left-column-container' container rowSpacing={3} width={'100%'} height={'100%'}>
                    <Grid id='top-right-img' size={12} sx={{ height: '30%', width:'100%', zIndex: 3}}>
                      <Box id='bottom-left-img-container' sx={{ height: '100%', width: '100%', zIndex: 4}}>
                        <FadeInAnimation delay={0.3} sx={{width: '100%', height: '100%'}}>
                          <img src={teamImg} loading='lazy' style={{objectFit: 'cover', width: '100%', height:'100%'}}  alt="Satokie Team Image" />
                        </FadeInAnimation>
                      </Box>
                    </Grid>
                    <Grid id='bottom-right-img' size={12} sx={{ height: '70%', width: '100%', zIndex: 3}}>
                      <Box sx={{height: '100%', width: '100%'}}>
                        <Grid container spacing={3} id='bottom-right-img-flex-container' sx={{width: '100%', height: '100%',}}>
                            <Grid size={12} sx={{width: '100%', height: '47%' }}>
                              <Box id='bottom-right-img-1' sx={{width: '100%', height: '100%'}}>
                              <FadeInAnimation delay={0.5} sx={{width: '100%', height: '100%'}}>
                                <img src={teamInspectionImg} loading='lazy' style={{objectFit: 'cover', width: '100%', height:'100%'}}  alt="Satokie Techs Image" />
                              </FadeInAnimation>
                              </Box>
                            </Grid>
                            <Grid size={12} sx={{width: '100%', height: '47%'}}>
                              <Box id='bottom-right-img-2' sx={{width: '100%', height: '100%'}}>
                              <FadeInAnimation delay={0.7} sx={{width: '100%', height: '100%'}}>
                                <img src={siteRowsImg} loading='lazy' style={{objectFit: 'cover', width: '100%', height:'100%'}}  alt="Satokie Site Rows Image" />
                              </FadeInAnimation>
                              </Box>
                            </Grid>                        
                        </Grid>
                      </Box>
                    </Grid>
                  </Grid>
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Section>
        <Footer isMobile={isMobile}/>
      </Paper>
    :
    //Desktop
      <Paper  sx={{position: 'relative', flexGrow: 1, overflowX: 'hidden', borderRadius: '0', backGroundColor: theme.palette.secondary.main}}>
        <Nav activeRoute={activeRoute} isMobile={isMobile}/>
        <Section id='hero' elevation={0} height={90} sx={{backgroundColor: theme.palette.primary.main}}>
          <Stack id='hero-parent-container' direction='row' justifyContent='center' alignItems='center' width='100%' height='100%'>
            <Stack id='hero-container' spacing={10} direction='row' justifyContent='center' alignItems='center' width='90%' height='100%'>
              <Stack id='hero-copy' width='45%'>
                <Typography variant='h3' sx={{fontSize: '3cqw', textWrap: 'wrap', fontWeight: 'bold', marginBottom: '4cqw'}} color={theme.palette.secondary.main}>We Build, Own & Operate Data Centers To Support The Bitcoin Network</Typography>
                <Button
                  variant='contained'
                  color='secondary'
                  sx={{marginBottom: '2cqw', width: 'fit-content', flexWrap: 'nowrap', fontSize: '1.2cqw', borderRadius: '.5rem'}}
                  onClick={() => document.querySelector('#footer-contact')?.scrollIntoView({ behavior: 'smooth' })}>
                  Partner with us now
                </Button>
              </Stack>
              <Stack id='hero-video' width='55%'>
                <VideoItem sx={{
                  width: '90%',
                  height: '70%',
                  boxShadow: '0px 16px 16px rgba(0,0,0, .5)',
                  objectFit: 'cover',
                  borderRadius: '1%',
                  marginBottom: '2cqw'
                }} videoUrl={videoUrl} isMobile={isMobile} typeString={'video/mp4'} />
              </Stack>
            </Stack>
          </Stack>
        </Section>
        <Section id='services' elevation={0} height={100} sx={{backgroundColor: theme.palette.secondary.main, maxHeight: '45vw', position: 'relative', zIndex: 0 }}>
          <Box id='grid-box'  sx={{ width: '100%', height: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center'}}>
            <FadeInUpAnimation sx={{height: '100%'}}>
              <Grid  sx={{ width: '90cqw', height: '100%'}} id='ticket-container' wrap='nowrap' container spacing={2}>
                <Grid id='ticket-1' size={3} position='relative'>
                  <VerticalTicketItem 
                    theme={theme}
                    icon={FactoryIcon}
                    contentTitle='Site Operations'
                    content='Let our expert teams manage your Bitcoin mining site’s day-to-day operations and maintenance, from traditional grid power to midstream natural gas solutions. With 95% average uptime across our managed sites, we ensure your mining operations run at peak efficiency.'
                    titleFontSize='1.3cqw'
                    contentFontSize='.9cqw'
                    iconSx={{width: '3cqw', height: 'auto'}}
                  ></VerticalTicketItem>
                </Grid>
                <Grid id='ticket-2' size={3} position='relative'>
                  <VerticalTicketItem
                    theme={theme}
                    icon={ConstructionIcon}
                    contentTitle="Site Acquisition & Development"
                    content='From site selection to site completion, our dedicated development team handles every step of creating your mining operation. We identify prime locations with reliable power access and transform them into state-of-the-art facilities, managing permits, construction, and infrastructure deployment.'
                    titleFontSize='1.3cqw'
                    contentFontSize='.9cqw'
                    iconSx={{width: '3cqw', height: 'auto'}}
                  ></VerticalTicketItem>
                </Grid>
                <Grid id='ticket-3' size={3} position='relative'>
                  <VerticalTicketItem
                    theme={theme}
                    icon={BuildIcon}
                    contentTitle='ASIC Repair'
                    content="Expert diagnostics and repair services to maximize your mining hardware's lifespan. Our professionally-trained technicians restore damaged hash boards and ASIC miners to optimal performance, with an above standard successful repair rate."
                    titleFontSize='1.3cqw'
                    contentFontSize='.9cqw'
                    iconSx={{width: '3cqw', height: 'auto'}}
                  ></VerticalTicketItem>
                </Grid>
                <Grid id='ticket-4' size={3} position='relative'>
                  <VerticalTicketItem
                    theme={theme}
                    icon={ElectricBoltIcon}
                    contentTitle='Energy Consultation'
                    content="Optimize your mining operation's power infrastructure with our expert energy consulting, including curtailment program setup. Our team analyzes consumption patterns, and implements smart curtailment strategies; delivering huge reductions in operational expenses."
                    titleFontSize='1.3cqw'
                    contentFontSize='.9cqw'
                    iconSx={{width: '3cqw', height: 'auto'}}
                  ></VerticalTicketItem>

                </Grid>
              </Grid>
            </FadeInUpAnimation>
          </Box>
        </Section>
        <Section id='metrics' elevation={0} height={30} sx={{backgroundColor: theme.palette.primary.main, maxHeight: '50vw'}}>
          <Box id='home-4-container'position='relative' width='100%' height='100%' display='flex' flexDirection='row' alignItems='center' justifyContent='center' zIndex={0}>
            <Box id='background-svg-container' position={'absolute'} sx={{ display: 'flex', alignItems: 'center', justifyContent:'center', width: '100%', height: '100%', zIndex: 1, overflow: 'hidden'}}>
              <img width='100%' style={{aspectRatio: '1/1'}} src={terralinesSvg} alt="background svg" />
            </Box>
            <Box id='content-container' position={'absolute'} sx={{ display:'flex', flexDirection: 'row', alignItems: 'center', textAlign: 'center', justifyContent:'space-around', width: '100%', height: '100%', zIndex: 2}}>
                <Box>
                  <Typography sx={{ fontSize: '6cqw', fontWeight: 'bold', textShadow: '0em .3em .5em rgba(0, 0, 0, 0.6) !important', color: theme.palette.secondary.main}}>140</Typography>
                  <Typography sx={{ fontSize: '1.5cqw', fontWeight: 'bold', color: theme.palette.secondary.main, opacity: .8}}>MEGAWATTS DEPLOYED</Typography>
                </Box>
                <Box>
                  <Typography sx={{ fontSize: '6cqw', fontWeight: 'bold', textShadow: '0em .3em .5em rgba(0, 0, 0, 0.6) !important', color: theme.palette.secondary.main}}>~7EH</Typography>
                  <Typography sx={{ fontSize: '1.5cqw', fontWeight: 'bold', color: theme.palette.secondary.main, opacity: .8}}>HASHRATE OPERATED</Typography>
                </Box>
                <Box>
                  <Typography sx={{ fontSize: '6cqw', fontWeight: 'bold', textShadow: '0em .3em .5em rgba(0, 0, 0, 0.6) !important', color: theme.palette.secondary.main}}>7</Typography>
                  <Typography sx={{ fontSize: '1.5cqw', fontWeight: 'bold', color: theme.palette.secondary.main, opacity: .8}}>SITES MANAGED</Typography>
                </Box>
                <Box>
                  <Typography sx={{ fontSize: '6cqw', fontWeight: 'bold', textShadow: '0em .3em .5em rgba(0, 0, 0, 0.6) !important', color: theme.palette.secondary.main}}>40</Typography>
                  <Typography sx={{ fontSize: '1.5cqw', fontWeight: 'bold', color: theme.palette.secondary.main, opacity: .8}}>TEAM MEMBERS</Typography>
                </Box>
            </Box>
          </Box>
        </Section>
        <Section id='team' elevation={0} sx={{padding: '2cqw', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', backgroundColor: theme.palette.secondary.main}}>
          <Stack width='100%' height='100%' direction='row' alignItems='stretch' justifyContent='center' spacing={5} paddingBottom={'2cqw'}>
            <ExecsInfoCard
              name='Jay Zapata'
              title='CEO'
              headerColor='#F4DFC2'
              headerWidth='50%'
              bio="With over a decade of experience in financial leadership and operational management, Jay has consistently delivered results by implementing strong financial controls and driving efficiency across diverse sectors. He has held key roles, including Optimization Director and Financial Controller for private equity backed portfolio companies, where he focused on financial strategy and operational performance. Before joining Satokie, Jay served as CFO of a $150MM revenue organization, where he oversaw complex financial portfolios and ensured strict regulatory compliance."
              image={jayHeadshot}
              imgAlt='Very Handsome Headshot of Jay Zapata'/>
            <ExecsInfoCard
              name='Shyke Lowers'
              title='COO'
              headerColor='#FDD1AD'
              headerWidth='58%'
              bio="Shyke brings a wealth of experience to the role of COO at Satokie Mining. A former Power Trader with vast knowledge in power markets and asset optimization gained through his experience operating a 120+MW battery generation portfolio in the ERCOT Market. Shyke utilizes his Power Markets expertise to lead the team's Demand Response and Power Procurement strategies. Shyke spends his time as COO laser focused on the strategic execution of the optimization initiatives and commercial scaling of Satokie's Operations."
              image={shykeHeadshot}
              imgAlt='Very Handsome Headshot of Shyke Lowers'/>
            <ExecsInfoCard
              name='Omar Alatorre'
              title='Director of Site Ops'
              headerColor='#C5A391'
              headerWidth='88%'
              bio="Omar has a demonstrated history of effectively overseeing large projects and guiding cross-functional teams to enhance operational efficiency. He gained extensive experience in operations and management by deploying preventive maintenance and technician operation teams for a 300+ MW Wind Farm portfolio, which is backed by one of the country's major capital infrastructure investors."
              image={omarHeadshot}
              imgAlt='Very Handsome Headshot of Omar Alatorre'/>
          </Stack>
          <Stack width='100%' height='100%' direction='row' alignItems='stretch' justifyContent='center' spacing={5}>
            <ExecsInfoCard
              name='Jose Ortega'
              title='CCO'
              headerColor='#F4DFC2'
              headerWidth='52%'
              bio="Jose is a founder and builder with successful exits in energy, clean technology & Software as a Service (SaaS), he has demonstrated exceptional entrepreneurial acumen. He played a pivotal role in scaling Revel by securing a $27.4 million Series A funding round and raised $75 million in debt equity for projects in the Midland Basin. His innovative efforts across multiple sectors have generated over $750 million in value under his leadership."
              image={joseHeadshot}
              imgAlt='Very Handsome Headshot of Jose Ortega'/>
            <ExecsInfoCard
              name='Gabe Procaccini'
              title='EVP, General Counsel'
              headerColor='#FDD1AD'
              headerWidth='95%'
              bio="Gabe Procaccini is a key executive leading the off-grid/stranded energy solutions business. With over 20 years of experience, he is a seasoned attorney and business advisor specializing in global energy transactions. His expertise spans upstream, midstream, and energy marketing transactions, as well as LNG, energy infrastructure development, energy M&A, joint ventures, and emerging energy products. Mr. Procaccini has a strong focus on decarbonization, including stranded gas solutions, low methane gas certification, and utility-scale battery storage systems."
              image={gabeHeadshot}
              imgAlt='Very Handsome Headshot of Gabe Procaccini'/>
          </Stack>
        </Section>
        <Section id='origins' elevation={0} sx={{backgroundColor: theme.palette.secondary.main}}>
          <Box padding={'2cqw 6cqw'} color={theme.palette.primary.main}>
            <Typography variant='h3' sx={{fontWeight: 'bold', fontSize: '3cqw'}}>Our Origins</Typography>
            <hr style={{height: '.3cqw', backgroundColor: theme.palette.primary.main}}/>
            <OriginsCopy sx={{lineHeight: '4cqh', fontSize: '1.6cqw'}}/>
          </Box>
          <Box sx={{display: 'flex', justifyContent: 'center'}}>
              <img src={partnersWebp} alt='Our three very handsome founders' style={{
                width: '70%', borderRadius: '5cqw', padding: ".3rem",
                background: "linear-gradient(90deg, #172D5E 0%, #C2C2C2 30%, #F8AF51 56%, #D95456 89%)"
              }} />
          </Box>
        </Section>
        <Section id='home-6' elevation={0} height={160} sx={{position: 'relative', backgroundColor: theme.palette.secondary.main, maxHeight: '160vw', zIndex: 0, paddingBottom: '8cqw'}}>
          <Box id='gallery-container' position='absolute' width={'100%'} height={'100%'} sx={{padding: '0 6cqw ', margin: '4rem 0', zIndex: 1}}>
            <Grid container id='gallery-parent-grid' spacing={6} width={'100%'} height={'90%'} sx={{zIndex: 2}}>
                <Grid id='gallery-left-column'size={6}>
                  <Box id='collumn-box-container' width={'100%'} height={'100%'}>
                    <Grid id='gallery-left-column-container' container rowSpacing={7.5} width={'100%'} height={'100%'}>
                      <Grid id='top-left-text' size={12} sx={{height: '30%', width:'100%', zIndex: 3}}>
                        <Box id='top-left-text-box'>
                          <Box id='top-left-text-title' sx={{marginBottom: '2cqh'}}>
                            <FadeInUpAnimation delay={0}>
                              <Typography variant='h3' sx={{fontWeight: 'bold', fontSize: '3cqw', color: theme.palette.primary.main}}>Setting the Standard for Bitcoin Mining Colocation</Typography>
                            </FadeInUpAnimation>
                          </Box>
                          <Box id='top-left-text-content' sx={{width: '90%'}}>
                            <FadeInUpAnimation delay={0.2}>
                              <Typography variant='h5' sx={{lineHeight: '4cqh', fontSize: '1.6cqw'}}>Our mission is to deliver cutting-edge solutions that optimize energy assets by leveraging Bitcoin Mining as a tool. Satokie Mining aims to embed itself at the intersection of Bitcoin mining and traditional energy markets and assets.</Typography>
                            </FadeInUpAnimation>
                          </Box>
                        </Box>
                      </Grid>
                      <Grid id='bottom-left-img' size={12} sx={{height: '70%', width: '100%', zIndex: 3}}>
                        <Box id='bottom-right-img-1' sx={{width: '100%', height: '100%'}}>
                          <FadeInAnimation delay={0.4} sx={{height: '100%', width: '100%'}}>
                            <img src={racksImg} loading='lazy' style={{objectFit: 'cover', width: '100%', height:'100%', aspectRatio: '3/2'}}  alt="Satokie Racks Image" />
                          </FadeInAnimation>
                        </Box>
                      </Grid>
                    </Grid>
                  </Box>
                </Grid>
                <Grid id='gallery-right-column' size={6} sx={{height: '100%'}}>
                <Box id='collumn-box-container' width={'100%'} height={'100%'}>
                  <Grid id='gallery-left-column-container' container rowSpacing={7.5} width={'100%'} height={'100%'}>
                    <Grid id='top-right-img' size={12} sx={{ height: '30%', width:'100%', zIndex: 3}}>
                        <Box id='bottom-left-img-container' sx={{ height: '100%', width: '100%', zIndex: 4}}>
                          <FadeInAnimation delay={0.3} sx={{ height: '100%', width: '100%'}}>
                            <img src={teamImg} loading='lazy' style={{objectFit: 'cover', width: '100%', height:'100%', aspectRatio: '1/3'}}  alt="Satokie Team Image" />
                          </FadeInAnimation>
                        </Box>
                    </Grid>
                    <Grid id='bottom-right-img' size={12} sx={{ height: '70%', width: '100%', zIndex: 3}}>
                      <Box sx={{height: '100%', width: '100%'}}>
                        <Grid container spacing={6} id='bottom-right-img-flex-container' sx={{width: '100%', height: '100%',}}>
                            <Grid size={12} sx={{width: '100%', height: '47%' }}>
                              <Box id='bottom-right-img-1' sx={{width: '100%', height: '100%'}}>
                                <FadeInAnimation delay={0.3} sx={{width: '100%', height: '100%'}}>
                                  <img src={teamInspectionImg} loading='lazy' style={{objectFit: 'cover', width: '100%', height:'100%', aspectRatio: '4/1'}}  alt="Satokie Techs Image" />
                                </FadeInAnimation>
                              </Box>
                            </Grid>
                            <Grid size={12} sx={{width: '100%', height: '47%'}}>
                              <Box id='bottom-right-img-2' sx={{width: '100%', height: '100%'}}>
                                <FadeInAnimation delay={0.3} sx={{width: '100%', height: '100%'}}>
                                  <img src={siteRowsImg} loading='lazy' style={{objectFit: 'cover', width: '100%', height:'100%', aspectRatio: '1/3'}}  alt="Satokie Site Rows Image" />
                                </FadeInAnimation>
                              </Box>
                            </Grid>                        
                        </Grid>
                      </Box>
                    </Grid>
                  </Grid>
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Section>
        <Footer isMobile={isMobile}/>
      </Paper>
    }
    </React.Fragment>
  );
}
