import React, { SyntheticEvent, useState } from 'react';
import Logo from './Logo';
import NavItems from './NavItems';
import {AppBar, Grid2 as Grid, useTheme, Toolbar, Stack, IconButton, Drawer, Box, List, ListItem, ListItemText, ListItemButton, Button, Typography}from '@mui/material';
import MenuIcon  from '@mui/icons-material/Menu';
import XIcon from '@mui/icons-material/X';
import LinkedInIcon from '@mui/icons-material/LinkedIn'
import CloseIcon from '@mui/icons-material/Close';
import Navigate, { useLocation, useNavigate } from 'react-router-dom';

interface NavProps {
  activeRoute: string,
  isMobile: boolean
}

export default function Nav({activeRoute, isMobile}: NavProps): React.JSX.Element {
  const theme = useTheme();
  const [drawerIsOpen, setDrawerIsOpen] = useState<boolean>(false);
  const navigate = useNavigate();
  const location = useLocation();

  
  const toggleDrawer = (e): void => {
    if(drawerIsOpen){
      setDrawerIsOpen(false);
    }else{
      setDrawerIsOpen(true);
    }
  }
  
  const handleScrollTo = (e: React.MouseEvent<HTMLButtonElement, MouseEvent> | SyntheticEvent, targetId: string): void  => {
    targetId === '/midstream' ? navigate('/midstream') : navigate('/')
    setTimeout(() => {
      setDrawerIsOpen(false);
      const target = document.getElementById(targetId);
      target?.scrollIntoView({behavior: 'smooth'})
    }, 100)
  }

  const midstreamScrollTo = (e: React.MouseEvent<HTMLButtonElement, MouseEvent> | SyntheticEvent, targetId: string): void  => {
    setTimeout(() => {
      setDrawerIsOpen(false);
      const target = document.getElementById(targetId);
      target?.scrollIntoView({behavior: 'smooth'})
    }, 100)
  }


  const handleNavigation = (event: SyntheticEvent, path: string) => {
    navigate(path);
    setTimeout(() => {
      midstreamScrollTo(event, 'midstream-hero')
    }, 100)
  }
  
  const mobileNavItems = [
    {id: 0, ref: '#services', text: 'Services', isActive: false, cb: (event) => handleScrollTo(event, 'services')},
    {id: 1, ref: '#about', text: 'About', isActive: false, cb: (event) => handleScrollTo(event, 'team')},
    {id: 2, ref: '#footer-contact', text: 'Contact', isActive: false, cb: (event) => handleScrollTo(event, 'footer-contact')},
    {id: 3, ref: '/midstream', text:'Midstream', isActive: false, cb: (event) => handleNavigation(event, '/midstream')},
  ]; 

  
  return (
    <React.Fragment>
      {isMobile ?
        <React.Fragment>
          <AppBar id='mobile-app-bar' component='nav' position='fixed' sx={{backgroundColor: 'transparent', backdropFilter: 'blur(10px)'}}>
            <Toolbar sx={{height: '100%'}}>
              <Stack direction='row' alignItems='space-between' justifyContent='space-between'sx={{width: '100%'}}>
                <IconButton sx={{width: '8cqw'}} onClick={(event) => handleScrollTo(event, 'hero')}>
                  <Logo />
                </IconButton>
                <IconButton size='medium' onClick={toggleDrawer}>
                  <MenuIcon fontSize='large' sx={{color: theme.palette.secondary.main, filter: 'drop-shadow(0em 0em 5px #000)'}}/>
                </IconButton>
              </Stack>
            </Toolbar>
          </AppBar>
          <Drawer anchor='right' open={drawerIsOpen} onClose={toggleDrawer}>
            <Box sx={{width: 200, height: '100%', backgroundColor: theme.palette.secondary.main}}>
              <Stack>
                <List>
                  <Stack direction='row' justifyContent='flex-end'>
                    <IconButton size='large' disableRipple disableFocusRipple  onClick={toggleDrawer}>
                      <CloseIcon fontSize='large' sx={{color: theme.palette.primary.main}}></CloseIcon>
                    </IconButton>
                  </Stack>
                  {mobileNavItems.map(item => (
                    <ListItem>
                      <ListItemButton onClick={item.cb}>
                        <ListItemText primary={<Typography sx={{color: theme.palette.primary.main}}>{item.text}</Typography>}></ListItemText>
                      </ListItemButton>
                    </ListItem>
                  ))}
                  <ListItem>
                    <Stack direction='row' justifyContent='flex-end' sx={{width: '100%'}}>
                      <IconButton href="https://x.com/SatokieMining" rel='noopener'>
                        <XIcon sx={{color: theme.palette.primary.main}} />
                      </IconButton >
                      <IconButton href="https://www.linkedin.com/company/satokie-mining" rel='noopener'>
                        <LinkedInIcon fontSize='large' sx={{color: theme.palette.primary.main}}/>
                      </IconButton>
                    </Stack>
                  </ListItem>
                </List>
              </Stack>
            </Box>
          </Drawer>
        </React.Fragment>
      :
        <Stack direction='row' position='fixed' justifyContent='space-between' sx={{width: '100%', height: '10%', zIndex: 100, boxShadow: '0px 5px 15px rgba(0, 0, 0, 0.1); 0px 1px 3px rgba(0, 0, 0, 0.2);', backgroundColor: 'transparent', backdropFilter: 'blur(10px)'}}>
          <NavItems 
            activeRoute={activeRoute} 
            isMobile={isMobile}
          />
        </Stack>
      }
    </React.Fragment>
  )
}