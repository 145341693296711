import React from 'react';
import Footer from '../components/Footer';
import { Box,  useTheme, Typography, Grid2 as Grid, Button, Stack, Paper, Divider} from '@mui/material';
//components
import Nav from '../components/Nav';
import Section from '../components/Section';
import GradientText from '../components/GradientText';
//assets
import blowOffImg from '../assets/images/midstream/blowoff-flame.webp';
import drillStackImg from '../assets/images/midstream/drillstack.webp';
import drillStackMobile from '../assets/images/midstream/tower-cropped-mobile.webp';
import tracksSvg from '../assets/images/midstream/tracks.svg';
import tracksMobile from '../assets/images/midstream/tracks-mobile.svg';
import flameImg from '../assets/images/midstream/flame.webp';



interface MProps {
  activeRoute: string,
  isMobile: boolean
}

export default function Midstream({activeRoute, isMobile}:MProps): React.JSX.Element {
  
  const theme = useTheme();
  const colors = {
    primary : theme.palette.primary.main,
    secondary: theme.palette.secondary.main,
    black: theme.palette.common.black
  }
  
  
  return (
    <React.Fragment>
      {isMobile ? 
        <React.Fragment>
          <Paper elevation={0} sx={{position: 'relative', overflowX: 'hidden', borderRadius: '0', backGroundColor: theme.palette.secondary.main}}>
            <Nav activeRoute={activeRoute} isMobile={isMobile}/>
            <Section id='midstream-hero' elevation={0} height={100} sx={{backgroundColor: theme.palette.primary.main, padding: '2rem 1rem 2rem 1rem'}}>
              <Stack id='midstream-hero-container' direction='column' alignItems='center' justifyContent='center' width='100%' height='100%'>
                <Stack id='midstream-hero-copy-container' direction='column' spacing={4} alignItems='center' justifyContent='center' sx={{width: '100%', height: '100%'}}>
                  <Stack id='midstream-hero-copy' direction='column' spacing={2} alignItems='flex-start' justifyContent='flex-end' sx={{width: '100%', height: '40%'}}>
                    <Typography variant='h5' sx={{ textWrap: 'wrap', fontWeight: 'bold'}} color={theme.palette.secondary.main}>Bringing our expertise in Bitcoin mining operations to your stranded assets.</Typography>
                    <Typography variant='body2' sx={{width: '100%'}} color={theme.palette.secondary.main}>Have flaring gas or a plugged line? We take that resource and convert it into profit for you and us.</Typography>
                    <Button
                      variant='contained'
                      color='secondary'
                      size='small'
                      sx={{marginBottom: '2cqw', width: 'fit-content', flexWrap: 'nowrap', fontSize: '.8rem', borderRadius: '.5rem'}}
                      onClick={() => document.querySelector('#footer-contact')?.scrollIntoView({ behavior: 'smooth' })}>
                      Partner with us now
                    </Button>
                  </Stack>
                  <Stack id='midstream-hero-img' direction='column' alignItems='center' justifyContent='center' sx={{width: '100%', height: '60%'}}>
                    <img src={blowOffImg} alt="An image of an oil stack with a burnoff tower engulfed in orange flame."
                      style={{
                        objectFit: 'contain', 
                        width: '100%',
                        height: '100%',
                        borderRadius: '1%',
                        transform: 'scaleX(-1)'
                      }}
                    />
                  </Stack>
                </Stack>
              </Stack>
            </Section>
            <Section id='midstream-problem-solution-section' elevation={0} height={170} sx={{backGroundColor: colors.secondary}}>
              <Stack id='midstream-problem-solution-parent-container'direction='column' width='100%' height='100%' sx={{ backgroundColor: colors.secondary}}>
                <Stack id='midstream-problem-container' position='relative' direction='row' sx={{height: '55%'}}>
                  <Stack id='midstream-problem-copy' position='absolute' justifyContent='flex-start' spacing={1} divider={<Divider sx={{border: `.05rem solid ${colors.primary}`}}/>} sx={{width: '95%', zIndex: 1, padding: '1rem 3.6rem 0 .5rem'}}>
                    <Stack id='midstream-problem-copy-title' spacing={0}>
                      <Typography variant='h5' color={colors.primary} fontWeight='bold'>Your</Typography>
                      <Typography variant='h5' color={colors.primary} fontWeight='bold'>Problem</Typography>
                    </Stack>
                    <Typography variant='subtitle1' color={colors.primary} textAlign='justify'>
                    Stranded natural gas and plugged pipelines can be a frustrating challenge for producers. When gas can’t be brought to market due to remote locations, limited infrastructure, or regulatory hurdles, it often ends up vented or flared—wasting a valuable resource and the hard work it took to extract it. Plugged pipelines add to the problem, creating bottlenecks that make it impossible to monetize production, leading to lost revenue, higher costs, and added environmental concerns. These situations leave a lot of potential on the table, but with the right solution, that wasted energy can be turned into opportunity.
                    </Typography>
                  </Stack>
                  <Stack id='midstream-problem-img' position='absolute' justifyContent='center' alignItems='center' sx={{ width: '100%', height: '100%', zIndex: 0}}>
                    <img src={drillStackMobile} 
                      style={{
                        objectFit: 'fill',
                        width: '100%',
                        height: '100%'
                      }}
                      alt="An Image of an industrial oil drill tower." />
                  </Stack>
                </Stack>
                <Stack id='midstream-solution-container' position='relative' direction='row' justifyContent='flex-start' alignItems='flex-start' sx={{ height: '45%'}}>
                  <Stack id='midstream-solution-copy' position='absolute' justifyContent='flex-start' spacing={1} divider={<Divider sx={{border: `.15rem solid ${colors.primary}`}}/>} sx={{ width: '100%', height: '100%', padding: '1rem 1rem 0 .5rem'}}>
                    <Stack id='midstream-solution-copy-title' spacing={3}>
                      <Typography variant='h5' color={colors.primary} textAlign='left' fontWeight='bold'>Our Solution</Typography>
                    </Stack>
                    <Typography variant='subtitle1' color={colors.primary} textAlign='left'>
                    At Satokie Midstream, we transform this challenge into an opportunity. When the quality and quantity of gas meet our criteria, we use state-of-the-art generators to convert the gas into electricity, which powers onsite Bitcoin mining operations. This innovative solution provides producers with an immediate, profitable use for otherwise wasted resources, ensuring no potential is left untapped.
                    <br/>
                    <br/>
                    <b>By turning stranded natural gas into digital assets, we maximize economic and environmental value, supporting producers in unlocking their full potential while minimizing waste.</b>
                    </Typography>
                  </Stack>
                  <Stack id='midstream-solution-img' position='absolute' justifyContent='flex-end' alignItems='flex-end'sx={{width: '100%', height: '100%'}}>
                    <img src={tracksMobile}
                      style={{
                        objectFit: 'contain',
                        width: '60%',
                      }}
                      alt="A image resembling a carbon atom lattice structure." />
                  </Stack>
                  </Stack>
                </Stack>
            </Section>
            <Section id='midstream-cta' height={20} sx={{backgroundColor: colors.black}}>
              <Stack id='midstream-cta-container' position='relative' direction='row' justifyContent='center' alignItems='center' width='100%' height='100%'>
                <Stack id='midstream-cta-img' position='absolute' direction='row' alignItems='flex-start' width='100%' height='100%' >
                    <img src={flameImg} 
                      style={{
                        objectFit: 'cover',
                        width: '77.5%'
                      }}
                      alt="An image of one quarter of a radial flame. Blue spouts of flame dominate with one orange flame at the bottom."
                    />
                </Stack>
                <Stack id='midstream-cta-copy' position='absolute' direction='column' width='100%' height='100%' sx={{padding: '.8rem'}} spacing={1}>
                  <Stack id='midstream-cta-copy2' justifyContent='flex-start' alignItems='flex-start' height='40%' width='100%'>
                    <GradientText
                      text='All we need is your gas.'
                      styles={{fontSize: '1.8rem', flexWrap: 'nowrap', fontWeight: 'bold', width: '100%', height: '100%', textAlign: 'center'}}
                    ></GradientText>
                  </Stack>
                  <Stack id='midstream-cta-copy1' width='100%' height='60%' direction='row' justifyContent='flex-end' alignItems='center'>
                    <Typography sx={{width: '55%'}} variant='subtitle1' color='secondary'>Join The Bitcoin World And Help Us Create An Off Grid Mining Site.</Typography>
                  </Stack>
                </Stack>
              </Stack>
            </Section>
            <Footer isMobile={isMobile}></Footer>
          </Paper>
        </React.Fragment>
        :
        <React.Fragment>
          <Paper elevation={0} sx={{position: 'relative', overflowX: 'hidden', borderRadius: '0', backGroundColor: theme.palette.secondary.main}}>
            <Nav activeRoute={activeRoute} isMobile={isMobile}/>
            <Section id='midstream-hero' elevation={0} height={80} sx={{backgroundColor: theme.palette.primary.main}}>
              <Stack direction='row' alignItems='center' justifyContent='center' width='100%' height='100%'>
                <Stack direction='row' spacing={3} alignItems='center' justifyContent='center' sx={{width: '80%', height: '100%'}}>
                  <Stack direction='column' spacing={6} alignItems='flex-start' justifyContent='center' sx={{width: '60%', height: '100%'}}>
                    <Typography variant='h2' sx={{ textWrap: 'wrap', fontWeight: 'bold', fontSize: '3cqw'}} color={theme.palette.secondary.main}>Bringing our expertise in Bitcoin mining operations to your stranded assets.</Typography>
                    <Typography variant='h6' sx={{width: '75%', fontSize: '1.3cqw'}} color={theme.palette.secondary.main}>Have flaring gas or a plugged line? We take that resource and convert it into profit for you and us.</Typography>
                    <Button
                      variant='contained'
                      color='secondary'
                      size='small'
                      sx={{marginBottom: '2cqw', width: 'fit-content', flexWrap: 'nowrap', fontSize: '1.2cqw', borderRadius: '.5rem'}}
                      onClick={() => document.querySelector('#footer-contact')?.scrollIntoView({ behavior: 'smooth' })}>
                      Partner with us now
                    </Button>
                  </Stack>
                  <Stack direction='column' alignItems='center' justifyContent='center' sx={{width: '40%', height: '90%'}}>
                    <img src={blowOffImg} alt="An image of an oil stack with a burnoff tower engulfed in orange flame."
                      style={{
                        objectFit: 'contain', 
                        width: '80%',
                        height: '80%',
                        borderRadius: '1%',
                      }}
                    />
                  </Stack>
                </Stack>
              </Stack>
            </Section>
            <Section id='midstream-problem-solution-section' elevation={0} height={175} sx={{backGroundColor: colors.secondary}}>
              <Stack id='midstream-problem-solution-parent-container'direction='column' width='100%' height='100%' sx={{ backgroundColor: colors.secondary}}>
                <Stack id='midstream-problem-container' direction='row' sx={{height: '50%', padding: '4rem 0 0 4rem'}}>
                  <Stack id='midstream-problem-copy' justifyContent='center' spacing={3} divider={<Divider sx={{border: `.15rem solid ${colors.primary}`}}/>} sx={{width: '65%'}}>
                    <Stack id='midstream-problem-copy-title' spacing={3}>
                      <Typography variant='h1' sx={{fontSize: '5cqw'}} color={colors.primary} fontWeight='bold'>Your</Typography>
                      <Typography variant='h1' sx={{fontSize: '5cqw'}} color={colors.primary} fontWeight='bold'>Problem</Typography>
                    </Stack>
                    <Typography variant='h5' sx={{fontSize: '1.8cqw'}} color={colors.primary} textAlign='justify'>
                    Stranded natural gas and plugged pipelines can be a frustrating challenge for producers. When gas can’t be brought to market due to remote locations, limited infrastructure, or regulatory hurdles, it often ends up vented or flared—wasting a valuable resource and the hard work it took to extract it. Plugged pipelines add to the problem, creating bottlenecks that make it impossible to monetize production, leading to lost revenue, higher costs, and added environmental concerns. These situations leave a lot of potential on the table, but with the right solution, that wasted energy can be turned into opportunity.
                    </Typography>
                  </Stack>
                  <Stack id='midstream-problem-img' justifyContent='center' alignItems='flex-end' sx={{ width: '35%'}}>
                    <img src={drillStackImg} 
                      style={{
                        objectFit: 'contain',
                        width: '90%'
                      }}
                      alt="An Image of an industrial oil drill tower." />
                  </Stack>
                </Stack>
                <Stack id='midstream-solution-container' direction='row' sx={{ height: '50%', padding: '4rem 4rem 4rem 0'}}>
                  <Stack id='midstream-solution-img' justifyContent='center' alignItems='flex-start'sx={{width: '35%'}}>
                    <img src={tracksSvg}
                      style={{
                        objectFit: 'contain',
                        width: '80%'
                      }}
                      alt="A image resembling a carbon atom lattice structure." />
                  </Stack>
                  <Stack id='midstream-solution-copy' justifyContent='center' spacing={3} divider={<Divider sx={{border: `.15rem solid ${colors.primary}`}}/>} sx={{ width: '65%'}}>
                    <Stack id='midstream-solution-copy-title' spacing={3}>
                      <Typography variant='h1' sx={{fontSize: '5cqw'}} color={colors.primary} textAlign='right' fontWeight='bold'>Our</Typography>
                      <Typography variant='h1' sx={{fontSize: '5cqw'}} color={colors.primary} textAlign='right' fontWeight='bold'>Solution</Typography>
                    </Stack>
                    <Typography variant='h5' sx={{fontSize: '1.8cqw'}}color={colors.primary} textAlign='justify'>
                    At Satokie Midstream, we transform this challenge into an opportunity. When the quality and quantity of gas meet our criteria, we use state-of-the-art generators to convert the gas into electricity, which powers onsite Bitcoin mining operations. This innovative solution provides producers with an immediate, profitable use for otherwise wasted resources, ensuring no potential is left untapped.
                    <br/>
                    <br/>
                    <b>By turning stranded natural gas into digital assets, we maximize economic and environmental value, supporting producers in unlocking their full potential while minimizing waste.</b>
                    </Typography>
                    </Stack>
                  </Stack>
                </Stack>
            </Section>
            <Section id='midstream-cta' height={20} sx={{background: 'linear-gradient(to right, rgb(4 9 16), black)'}}>
              <Stack id='midstream-cta-container' direction='row' justifyContent='flex-start' alignItems='center' width='100%' height='100%'>
                <Stack id='midstream-cta-img' direction='row' justifyContent='flex-start' width={window.innerWidth > 960 ? '20%' : '45%'} height='100%'>
                    <img src={flameImg} 
                      style={{
                        objectFit: 'cover',
                        width: '100%'
                      }}
                      alt="An image of one quarter of a radial flame. Blue spouts of flame dominate with one orange flame at the bottom."
                    />
                </Stack>
                <Stack id='midstream-cta-copy' direction='column' width='85%' height='100%' sx={{padding: '0 1rem 0 1rem'}}>
                  <Stack id='midstream-cta-copy1' width='100%' height='50%' justifyContent='center' alignItems='flex-start'>
                    <Typography variant='h4' sx={{fontSize: '2cqw'}} color='secondary'>Join The Bitcoin World And Help Us Create An Off Grid Mining Site.</Typography>
                  </Stack>
                  <Stack id='midstream-cta-copy2' justifyContent='flex-end' alignItems='flex-end' height='50%'>
                    <GradientText
                      text='All we need is your gas.'
                      styles={{fontSize: '4cqw'}}
                    ></GradientText>
                  </Stack>
                </Stack>
              </Stack>
            </Section>
            <Footer isMobile={isMobile}></Footer>
          </Paper>
        </React.Fragment>
      }
    </React.Fragment>
  )
}