import ContactFormData from '../model/contact/ContactFormData';


export const submitContactForm = async (formData: ContactFormData) => {
  const url: string = (process.env.REACT_APP_BACKEND_URL as string);
  
  //payload
  const payload = new FormData();
  Object.entries(formData).forEach(([key, value]) => {
    payload.append(key,value);
  });

  let response;
  
  try{
    response = await fetch( `${url}/api/contact/` , {
      method: 'POST',
      body: payload
    });

    if (!response.ok) {
      const errorData = await response.json();
      console.error('Form submission error:', errorData);
      throw new Error(`Request failed with status ${response.status}`);
    }

    console.log('Form submitted successfully:', await response.json());
    return response;

    return response
  }catch( e: any){
    console.log(`Error on Form Submission: ${e.message}`);
    return response
  }
}