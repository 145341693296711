import React from 'react';
import { Grid2 as Grid, Link, Box} from "@mui/material";
import logo from '../assets/images/logo-1.png';

export default function Logo(): React.JSX.Element{


  
  return (
      <img src={logo} style={{
        objectFit: 'contain',
        position: 'absolute',
        width: '100%',
        filter: 'drop-shadow(0em 0em 5px #000)'
        
      }} />
  )
}