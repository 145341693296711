import React, { useState, useEffect } from 'react';
import {Routes, Route} from 'react-router-dom';
import Home from './Pages/Home';
import UnderConstruction from './Pages/UnderConstruction';
import Midstream from './Pages/Midstream';

export default function AppRouter() {
  const [active, setActive] = useState(false);
  const [isMobile, setIsMobile] = useState(false);
  const [isTablet, setIsTablet] = useState(false);

  useEffect(() => {
    const isMobileDevice = window.innerWidth < 450;
    const isTabletDevice = window.innerWidth > 450 && window.innerWidth < 685;
    setIsMobile(isMobileDevice);
    setIsTablet(isTabletDevice)

    const handleResize = () => {
      setIsMobile(window.innerWidth < 450);
      setIsTablet(window.innerWidth < 685);

    };

    window.addEventListener('resize', handleResize);

    return () => window.removeEventListener('resize', handleResize); 

  }, []);

  return (
    <Routes>
      <Route path='/midstream' element={<Midstream activeRoute={'/midstream'}isMobile={isMobile}/>}/>
      <Route path='/' element={<Home activeRoute={'/'} isMobile={isMobile}/>}/>
      <Route path='*' element={<UnderConstruction isMobile={isMobile} notFound={true} />} />
    </Routes>
  )
}