import React from 'react';
import { styled, Box, Typography, Theme, SvgIcon, CSSObject, Stack } from "@mui/material";
import { OverridableComponent } from '@mui/material/OverridableComponent';
import { SvgIconTypeMap } from '@mui/material';

export const TicketItem = styled(Box)(({theme}) => ({
  backgroundColor: 'rgba(0,0,0,0)',
  borderRadius: '0',
  textAlign: 'center',
  objectFit: 'contain',
  width: '100%',
  height: '90%',
  color: theme.palette.text.secondary
  , display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center'
}));

interface VerticalTicketItemProps{
  theme?: Theme,
  icon: OverridableComponent<SvgIconTypeMap<{}, "svg">> & { muiName: string;},
  contentTitle: string,
  titleFontSize?: string,
  content: string,
  contentFontSize?: string,
  iconSx?: CSSObject

}

export default function VerticalTicketItem({theme, icon, contentTitle, titleFontSize, content, contentFontSize, iconSx }: VerticalTicketItemProps){

  return(
  <Box sx={{ width: '100%', height: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center'}}>
    <Box id='ticket-image' sx={{width: '100%', height: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center'}}position='absolute' zIndex={1} >
      <TicketItem><svg id="Rxb0gwuOd2RZZaz3" fill="#062e60" viewBox="0 0 109.7036 146.0043"><g id="CTxoJCnxpADD2SgY"><path id="YAYqSJyBaBu2GALT" d="M103.45016064,0 L6.25338984,0 C6.25338984,3.44086003 3.4707799,6.25338984 0,6.25338984 L0,139.75091906 C3.44086003,139.75091906 6.25338984,142.53351946 6.25338984,146.00431795 L103.45016064,146.00431795 C103.45016064,142.56341906 106.23276104,139.75091906 109.70355953,139.75091906 L109.70355953,6.25338984 C106.26266064,6.25338984 103.45016064,3.4707799 103.45016064,0 Z" ></path></g></svg></TicketItem>
    </Box>
    <Box id='ticket-content' position='absolute' flexWrap='nowrap' zIndex={2} sx={{width: '80%', height: '90%', display:'flex',flexDirection:'column', justifyContent:'center', alignItems:'center'}} >
      <Stack direction='row' alignItems='flex-end' justifyContent='center' height='25%'>
        <SvgIcon 
          component={icon}
          color='secondary'
          fontSize='large'
          sx={iconSx}
        ></SvgIcon>
      </Stack>
      <Box sx={{width: '80%', height: '10%', display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
        <Typography textAlign='center' color={theme?.palette.secondary.main} sx={{fontSize: titleFontSize, fontWeight: 'bold', marginBottom:'calc(.5vw + .5vh)', textWrap: 'nowrap'}}>{contentTitle}</Typography>
      </Box>
      <Box sx={{width: '80%', height: '45%'}}>
        <Typography sx={{fontSize: contentFontSize}} textAlign='justify' color={theme?.palette.secondary.main}>{content}</Typography>
      </Box>
    </Box>
  </Box>
  )
}