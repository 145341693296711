import React from 'react';
import { Card, Stack, Paper, Typography, Box, useTheme } from '@mui/material';
import FadeInAnimation from './FadeIn';

interface CardProps {
  isMobile?: boolean,
  name: string,
  title: string,
  headerColor: string,
  headerWidth?: string,
  bio: string,
  image: string,
  imgAlt: string,
}


export default function ExecsInfoCard({isMobile=false, name, title, headerColor, headerWidth, bio, image, imgAlt }: CardProps): React.JSX.Element {
  const theme = useTheme();

  return (
  <React.Fragment>
    {isMobile ? 
    
      <FadeInAnimation delay={.2}>
        <Paper sx={{minWidth: '80%', height: 'auto',  border: '.1rem solid #E6E6E6', padding: '1rem 0 0 1rem', backgroundColor: '#F5F5F5', borderRadius: '1.2rem'}} elevation={0} >
          <Stack direction='column'>
            <Stack direction='column' alignItems='center' sx={{backgroundColor: headerColor, width: '95%', padding: '1cqw', borderRadius: '.5rem'}}>
              <Typography variant='h6'>{name},</Typography>
              <Typography variant='h6' textAlign='right' alignItems='flex-end' sx={{ fontSize: '4cqw' }}>{title}</Typography>
            </Stack>
            <br></br>
            <Typography variant='subtitle1' sx={{margin: '0 1rem 1rem 0'}}>{bio}</Typography>
            <Stack direction='row' justifyContent='center' sx={{width: '100%', margin: '-2cqw 0 2cqw 0', borderRadius: '15%'}}>
              <img style={{objectFit: 'contain', width: '50%', borderRadius: '1.2rem 0 1.2rem 0'}} src={image} alt={imgAlt} />
            </Stack>
          </Stack>
        </Paper>
      </FadeInAnimation>
    
    :
    // Desktop
    <Card sx={{ width: '20%', height: 'auto', border: '.1rem solid #E6E6E6', padding: '1rem 0 0 1rem', backgroundColor: '#F5F5F5', borderRadius: '1.2rem' }}>
    <Stack direction='column' justifyContent='space-between' height={'100%'}>
      <Box>
        <Stack direction='row' justifyContent='flex-start' alignItems='center' sx={{ height: '10%', backgroundColor: headerColor, width: headerWidth, padding: '.5cqw', borderRadius: '.5cqw' }}>
          <Typography variant='h5' sx={{ marginRight: '.5rem', fontSize: '1.0cqw' }}>{name},</Typography>
          <Typography variant='h6' textAlign='left' alignItems='flex-end' sx={{ fontSize: '.8cqw' }}>{title}</Typography>
        </Stack>
        <br></br>
        <Typography variant='subtitle1' sx={{ margin: '0 1rem 1rem 0', height: '80%', fontSize: '.8cqw' }}>
          {bio}
        </Typography>
      </Box>
      <Stack direction='row' justifyContent='center' sx={{ width: '100%', margin: '-1cqw 0 1cqw 0' }}>
        <img style={{ objectFit: 'contain', width: '60%' }} src={image} alt={imgAlt} />
      </Stack>
    </Stack>
    </Card >
    }
  </React.Fragment>
  )
}