import React from 'react';
import { CSSObject, Typography } from '@mui/material';

interface Props {
  text: string;
  styles?: CSSObject
}

export default function GradientText({ text, styles }: Props) {
  return( 
    <Typography 
      sx={{
        ...styles,
        background: 'linear-gradient(to bottom right, #313131, #C2C2C2, #F8AF51, #D95456)',
        WebkitBackgroundClip: 'text',
        WebkitTextFillColor: 'transparent',
      }}
      >
      {text}
    </Typography>
  )
};
