import React from "react"
import { CSSObject, Typography } from "@mui/material"

export default function OriginsCopy({ sx } : { sx?: CSSObject }): React.JSX.Element {
  return (
  <Typography sx={sx}>
    <p>The seeds of Satokie Mining were planted years before its official launch. In college, Shyke and Omar bonded over their shared fascination with Bitcoin and its potential to revolutionize industries. Summer 2019 marked the earliest roots of their collaboration at “Club Unity,” a house on West Street in Stillwater, where initial ideas for a Bitcoin mining and repair company began to take shape. However, it wasn't until early 2022 that their conversations turned into actionable plans.</p>
    <p>By Memorial Day weekend of 2022, Shyke and Omar connected with Jay Zapata through LinkedIn. Jay, already an experienced Bitcoin miner under JZD Holdings, brought a wealth of knowledge and practical experience to the table. From May to December 2022, they spent time building trust, refining their vision, and laying the groundwork for what would become Satokie Mining.</p>
    <p>In 2023, everything fell into place. They raised initial capital, solidified their partnership, and officially launched Satokie Mining with the formation of their first LLC. Their focus on operations and maintenance (O&M) allowed them to offer a unique value proposition in the Bitcoin mining industry.</p>
    <p>Things truly took off in Q4 2023. With a clear strategy for Bitcoin miner repair and hosting, our company expanded its footprint and strengthened its market position. By April 2024, we launched our first operational site, Kyle, located in West Texas. <b>This milestone was followed by reaching over 50MW of capacity within the following five months.</b></p>
    <p>We did not stop there. <b>In just nine months, we grew from one site to more than 15, breaking records and setting new standards across the industry.</b> Our team also has expanded rapidly, growing from fewer than 10 people to over 40 within a year. Together, we're creating something far beyond Bitcoin operations, with exciting plans for Satokie's future to be unveiled soon.</p>
    <p style={{marginBottom: 0}}>From a college dream to an industry leader, Satokie's story is one of collaboration, innovation, and relentless grit. <b>What started as a simple idea has become the company that turns challenges into opportunities and sets new benchmarks for what's possible.</b></p>
  </Typography> 
)}