import React from 'react';
import { Grid2 as Grid, Link, Typography, useTheme, Stack, IconButton} from '@mui/material';
import { useLocation, Navigate, useNavigate } from 'react-router-dom';
import XIcon from '@mui/icons-material/X';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import Logo from './Logo';

interface NIProps {
  isMobile: boolean,
  activeRoute: string
}


export default function NavItems({isMobile, activeRoute}: NIProps): React.JSX.Element {
  const theme = useTheme();
  const navigate = useNavigate();
  const navLinkStyle = {
    color: theme.palette.secondary.main, 
    textWrap: 'nowrap'
  };
  
  
  const XIconStyle = {
    fontSize: 'calc(1vw + 1vh)', marginTop: 'calc(.65vw + .65vh)', marginRight: 'calc(4vw+4vh)', marginLeft: '1.5rem', color: theme.palette.secondary.main, textDecoration: 'none', textWrap: 'nowrap', filter: 'drop-shadow(0em 0em 5px #000)'
  }
  
  const LinkedInIconStyle = {
    fontSize: 'calc(1.2vw + 1.2vh)', marginTop: 'calc(.55vw + .55vh)', marginRight: 'calc(.4vw+ .4vh)', color: theme.palette.secondary.main, textDecoration: 'none', textWrap: 'nowrap', filter: 'drop-shadow(0em 0em 5px #000)'
  }
  
  const navItems = [
    {id: 0, ref: 'services', text: 'Services', isActive: false},
    {id: 1, ref: 'team', text: 'About', isActive: false},
    {id: 2, ref: 'footer-contact', text: 'Contact', isActive: false},
    {id: 3, ref: '/midstream', text:'Midstream', isActive: false},
  ]; 

  const handleScrollTo = (e, ref) => {
    e.preventDefault();
    if(ref === '/midstream'){
      navigate('/midstream')

    }else{
      navigate('/')
    }
    setTimeout(() => {
      const target = document.getElementById(ref === '/midstream'? 'midstream-hero': ref);
      target?.scrollIntoView({behavior: 'smooth'})
    }, 100)
  }
  
  
  return (
    <Stack direction='row' width='100%' margin='2rem'>
      <Stack direction='row' width='50%' justifyContent='flex-start'>
        <IconButton disableRipple={true} sx={{width : '10%'}} onClick={(e) => handleScrollTo(e,  'hero')}>
          <Logo />
        </IconButton>
      </Stack>
      <Stack direction='row' width='50%' justifyContent='flex-end'>
      {navItems.map( item => (
        <Link
          key={item.id} href={item.ref}
          onClick={(e) => handleScrollTo(e, item.ref)} sx={navLinkStyle} style={{
            marginTop: '0',
            textUnderlineOffset: '.5rem',
            textDecorationColor: 'rgba(255,255,255,.5)'
          }} underline='hover'>
          <Typography sx={{fontSize: isMobile ? '2cqw': 'calc(1vw + 1vh)', margin: isMobile ? '.8cqw': 'calc(.5vw + .5vh)', textShadow: '0em 0em .6em rgba(0,0,0,.9) !important' ,textDecoration: activeRoute === item.ref ? 'underline': 'none', textUnderlineOffset: '.5rem'}}>{item.text}</Typography>
        </Link>
      ))}
      <Link href="https://x.com/SatokieMining" rel='noopener'>
        <XIcon sx={isMobile ? {fontSize: '3cqw', marginTop: '1.5cqw', marginRight: '.3cqw', marginLeft: '2cqw', color: theme.palette.secondary.main, textDecoration: 'none', textWrap: 'nowrap'} : XIconStyle}/>
      </Link>
      <Link href="https://www.linkedin.com/company/satokie-mining" rel='noopener'>
        <LinkedInIcon sx={isMobile ? { fontSize: '3.8cqw', marginTop: '1.2cqw', marginRight: '.1cqw', color: theme.palette.secondary.main, textDecoration: 'none', textWrap: 'nowrap'}: LinkedInIconStyle} />
      </Link>
      </Stack>
    </Stack>
  )
}